var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "send-mail" }, [
    _c(
      "div",
      { staticClass: "send-mail-container" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRule,
              "label-width": "80px",
            },
          },
          [
            _c(
              "div",
              { staticClass: "send-mail-header" },
              [
                _c("h1", [_vm._v("写邮件")]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.sendEmail } },
                  [_vm._v("发送邮件")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "send-mail-content" },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收件人：", prop: "receiver" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "no-border",
                            attrs: {
                              "popper-class": "receiver-selector",
                              size: "max",
                              placeholder: "",
                              multiple: "",
                              filterable: "",
                            },
                            on: { "remove-tag": _vm.removeTag },
                            model: {
                              value: _vm.form.receiver,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "receiver", $$v)
                              },
                              expression: "form.receiver",
                            },
                          },
                          [
                            _vm._l(_vm.receiverList, function (item, index) {
                              return [
                                item.teamId
                                  ? _c(
                                      "el-option",
                                      {
                                        key: item.teamId,
                                        attrs: {
                                          label: item.teamName,
                                          value: item.teamId,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.receiverID(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "icon-new" },
                                          [
                                            index == 0
                                              ? _c("img", {
                                                  attrs: {
                                                    src: require("@src/assets/images/sendMail/ic_company.png"),
                                                    alt: "",
                                                  },
                                                })
                                              : _c("img", {
                                                  attrs: {
                                                    src: require("@src/assets/images/sendMail/ic_group_portrait.png"),
                                                    alt: "",
                                                  },
                                                }),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(item.teamName) +
                                              "\n                                    "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _c(
                                      "el-option",
                                      {
                                        key: item.userId,
                                        attrs: {
                                          label: `${item.realName}@${item.nickName}`,
                                          value: item.userId,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.receiverID(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "icon-new" },
                                          [
                                            item.avatarUrl
                                              ? _c("img", {
                                                  attrs: {
                                                    src: item.avatarUrl,
                                                    alt: "",
                                                  },
                                                })
                                              : _c("span", {
                                                  staticClass:
                                                    "icon icon-member",
                                                }),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(item.realName) +
                                              "@" +
                                              _vm._s(item.nickName) +
                                              "\n                                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "no-border",
                        attrs: { label: "主题：", prop: "subject" },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.subject,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "subject", $$v)
                            },
                            expression: "form.subject",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            staticClass: "upload",
                            attrs: {
                              action: _vm.formatUploadUrl(
                                "/Data/UploadFileToOSS"
                              ),
                              data: _vm.upLoadData,
                              headers: _vm.fileUploadHeaders,
                              "on-remove": _vm.onRemove,
                              "on-success": _vm.fileUploadSuccess,
                              "with-credentials": true,
                              accept: `.doc,.docx,.zip,.rar,.xls,.xlsx`,
                              "file-list": _vm.fileList,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "no-border",
                                attrs: { size: "small" },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@src/assets/images/sendMail/attach-iocn-default.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(
                                  "\n                                添加附件\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-row"),
                  ],
                  1
                ),
                _c("el-row", [
                  _c("script", {
                    attrs: {
                      id: "container",
                      name: "content",
                      type: "text/plain",
                    },
                  }),
                ]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }