<template>
    <div class="send-mail">
        <div class="send-mail-container">
            <el-form ref="form" :model="form" :rules="formRule" label-width="80px">
                <div class="send-mail-header">
                    <h1>写邮件</h1>
                    <el-button type="primary" @click="sendEmail">发送邮件</el-button>
                </div>

                <div class="send-mail-content">
                    <el-row>
                        <el-form-item label="收件人：" prop="receiver">
                            <el-select v-model="form.receiver"
                                    class="no-border"
                                    popper-class="receiver-selector"
                                    size="max"
                                    placeholder=""
                                    multiple
                                    filterable
                                    @remove-tag="removeTag"
                            >
                                <template v-for="(item, index) in receiverList">
                                    <el-option
                                        v-if="item.teamId"
                                        :key="item.teamId"
                                        :label="item.teamName"
                                        :value="item.teamId"
                                        @click.native="receiverID(item)"
                                    >
                                        <span class="icon-new">
                                            <img src="~@src/assets/images/sendMail/ic_company.png" alt="" v-if="index == 0">
                                            <img src="~@src/assets/images/sendMail/ic_group_portrait.png" alt="" v-else>
                                        </span>
                                        <span>
                                            {{ item.teamName }}
                                        </span>
                                    </el-option>

                                    <el-option
                                        v-else
                                        :key="item.userId"
                                        :label="`${item.realName}@${item.nickName}`"
                                        :value="item.userId"
                                        @click.native="receiverID(item)"
                                    >
                                        <span class="icon-new">
                                            <img
                                                v-if="item.avatarUrl"
                                                :src="item.avatarUrl"
                                                alt="">
                                            <span
                                                class="icon icon-member"
                                                v-else>
                                            </span>
                                        </span>
                                        <span>
                                            {{ item.realName }}@{{item.nickName}}
                                        </span>
                                    </el-option>
                                </template>

                            </el-select>
                        </el-form-item>
                    </el-row>

                    <el-row>
                        <el-form-item class="no-border" label="主题：" prop="subject">
                            <el-input v-model="form.subject"></el-input>
                        </el-form-item>
                    </el-row>

                    <el-row>
                        <el-row>
                            <el-upload
                                ref="upload"
                                class="upload"
                                :action="formatUploadUrl('/Data/UploadFileToOSS')"
                                :data="upLoadData"
                                :headers="fileUploadHeaders"
                                :on-remove="onRemove"
                                :on-success="fileUploadSuccess"
                                :with-credentials="true"
                                :accept="`.doc,.docx,.zip,.rar,.xls,.xlsx`"
                                :file-list="fileList">
                                <el-button size="small" class="no-border">
                                    <img src="~@src/assets/images/sendMail/attach-iocn-default.png" alt="">
                                    添加附件
                                </el-button>
                            </el-upload>
                        </el-row>
                        <el-row>
                        </el-row>
                    </el-row>

                    <el-row>
                        <script id="container" name="content" type="text/plain"></script>
                    </el-row>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import socialService from '#/js/service/socialService.js';
    import jobService from '#/js/service/jobService.js';
    import userService from '#/js/service/userService.js';

    const MapTitle = {
        name: {
            show: false,
            label: '职位名称'
        },
        description: {
            show: false,
            label: '职位描述'
        },
        salary: {
            show: false,
            label: '月薪'
        },
        analysis: {
            show: false,
            label: '职位分析'
        },
        targetCompany:{
            show: false,
            label: '所属公司'
        },
        locations: {
            show: false,
            label: '工作地区'
        },
        recruitingCount: {
            show: false,
            label: '招聘人数'
        },
        minYearOfExperience: {
            show: false,
            label: '工作年限'
        },
        minDegree: {
            show: false,
            label: '最低学历'
        },
        emergencyDegree: {
            show: false,
            label: '紧急程度'
        },
        difficultDegree: {
            show: false,
            label: '难易程度'
        },
        jobId: {
            show: false,
            label: '职位链接'
        }
    };

    const FILT_SUFFIX = {
        'doc': true,
        'docx': true,
        'zip': true,
        'rar': true,
        'xls': true,
        'xlsx': true
    }

    const FILT_ICON = {
        'doc': 'icon-type-doc',
        'docx': 'icon-type-doc',
        'zip': 'icon-type-compress',
        'rar': 'icon-type-compress',
        'xls': 'icon-type-xls',
        'xlsx': 'icon-type-xls',
        'else': 'icon-type-default'
    }

    const MB = 1024 * 1024;

    export default {
        name: 'sendMail',
        data() {
            return {
                editor: null,
                batchNo: '',
                jobData: [],
                ueReady: false,
                fileList: [],
                content: '',
                isShare: false,
                subject: '',
                fileUploadHeaders: {
                    'RequestVerificationToken': this.$store.state.verificationToken.verificationToken
                },
                upLoadData: {
                    __RequestVerificationToken: this.$store.state.verificationToken.verificationToken,
                    purpose: 'AtAttachment'
                },
                containFirm: false,
                receiverList: {},
                form: {
                    subject: '',
                    receiver: [],
                    person_receiverID: {},
                    group_receiverID: {},
                    fileIds: []
                },
                formRule: {
                    subject: [
                        {
                            required: true,
                            message: '请填写主题',
                            trigger: 'blur'
                        }
                    ],
                    receiver: [
                        {
                            required: true,
                            message: '收件人不能为空',
                            trigger: 'blur'
                        }
                    ]
                }
            }
        },
        created(){
            this.loadJs("//hstatic.hirede.com/lbd/js/UE/ueditor.min.js");
        },
        async mounted() {
            
        },
        methods: {
            formatUploadUrl(url) {
                return _host.portal + url;
            },
            loadJs(url,callback){
                let script=document.createElement('script');
                let self = this;
                script.type="text/javascript";
                script.onload=function(){
                    let showMapTitle = JSON.parse(localStorage.getItem('checkedShareFilds') ? localStorage.getItem('checkedShareFilds') : '[]');

                    for(var key in MapTitle){
                        MapTitle[key].show = false;
                    }

                    for(var key in showMapTitle){
                        MapTitle[showMapTitle[key]].show = true;
                    }

                    // console.log(MapTitle);

                    UE.delEditor('container');

                    self.batchNo = self.$route.query.batchNo || false;
                    self.isShare = self.$route.query.from === 'batchShare';

                    self.editor = UE.getEditor('container', {
                        autoHeight: false,
                        toolbars: [
                            ['fullscreen', 'fontfamily', 'fontsize', 'forecolor', 'bold', 'italic', 'underline', 'strikethrough', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'justifyleft', 'justifyright', 'justifycenter', 'link']
                        ],
                        elementPathEnabled: false,
                        wordCount: false,
                        serverUrl: ''
                    });

                    self.editor.ready(() => {
                        self.ueReady = true;
                        self.init();
                    });
                }
                script.src=url;
                document.body.appendChild(script);
            },
            async init() {
                window.vuetest = this;
                let time = new Date();
                await socialService
                    .getAllEmailAddress(true)
                    .then(res => {
                        let person_list = {},
                            group_list = {};
                        res.map(group => {
                            group_list[group.teamId] = group;

                            if(group.teamId === '0'){
                                group.list.map(person => {
                                    person_list[person.userId] = person;
                                });
                            }
                        });

                        this.receiverList = Object.assign(group_list, person_list);
                    }).catch(err => {
                        console.log(err)
                    });

                if(this.isShare){
                    await jobService
                        .getShareJobInfo(this.$route.query.batchNo)
                        .then(res => {
                            this.jobData = res;
                            this.jobToHtml();
                        })
                        .catch(err => {
                            console.log(err)
                        });
                } else {
                    await jobService
                        .getJobInfo(this.$route.query.batchNo)
                        .then(res => {
                            this.jobData = res.model;
                            if(res.fileId && localStorage.getItem('isSendFile') === 'true'){
                                this.form.fileIds.push(res.fileId);
                                this.fileList.push({name: '职位附件', response: {
                                        data: {
                                            id: res.fileId
                                        }
                                    }})
                            }
                            this.jobToHtml();
                        })
                }

                await userService
                    .getSubject()
                    .then(res => {
                        this.firmShortName = res.firmShortName;
                    });
                this.form.subject = this.firmShortName + '职位' + ' - ' + time.getFullYear() + (time.getMonth() + 1) + time.getDate();
            },
            test(value) {
                console.log(this);
                console.log(value);
            },
            async sendEmail() {
                this.$refs.form.validate((vaiId) => {
                    if (vaiId) {
                        let param = {
                            UserIds: this.getReceiverIDList(),
                            content: this.editor.getContent(),
                            subject: this.form.subject,
                            containFirm: this.containFirm,
                            fileIds: this.form.fileIds,
                            IsBatchShare: this.isShare,
                        }

                        socialService
                            .sendEmail(this.$route.query.batchNo, param)
                            .then(res => {
                                // let url = `/Headhunting/MyCompany.html#/jobCreate/multi/publishResult?batchNo=${this.batchNo}&type=emailresult`;
                                let url = `/#/jobCreate/multi/publishResult?batchNo=${this.batchNo}&type=emailresult`;
                                if((res === null) || (res && res.length == 0)){
                                    shortTips('发送成功！');
                                    setTimeout(() => {
                                        location.href = url;
                                    },1000)
                                } else if(res && res.length > 0) {
                                    let str = '';
                                    res.map((item) => {
                                        str = str + this.receiverList[item].realName + '、'
                                    });
                                    str = str.slice(0, str.length - 1 ) + '的邮箱非企业邮箱，发送失败，请提醒他们将绑定邮箱改为企业邮箱';
                                    this.$alert(str,'提示',{
                                        confirmButtonText: '确定',
                                        callback: () => {
                                            location.href = url;
                                        }
                                    });
                                }
                            }).catch()
                    } else {
                        shortTips('请检查邮件信息是否都已填写完毕。')
                    }
                })
            },
            receiverID(item) {
                if (item.userId) {
                    if (this.form.person_receiverID[item.userId]) {
                        // this.form.person_receiverID[item.userId] = false;
                        delete this.form.person_receiverID[item.userId];
                    } else {
                        this.form.person_receiverID[item.userId] = true;
                    }
                } else {
                    if (item.list) {
                        if (this.form.group_receiverID[item.teamId]) {
                            if(item.itemId === '0'){
                                this.containFirm = false;
                            }
                            delete this.form.group_receiverID[item.teamId];
                        } else {
                            this.form.group_receiverID[item.teamId] = item.list;
                            this.containFirm = item.teamId === '0';
                        }
                    }
                }
            },
            removeTag(id){
                if(this.receiverList[id]){
                    this.receiverID(this.receiverList[id]);
                }
            },
            getReceiverIDList() {
                let list = [], cacheObj = {};
                for (let key in this.form.group_receiverID) {

                    this.form.group_receiverID[key].map((item) => {
                        if (!this.form.person_receiverID[item.userId]) {
                            cacheObj[item.userId] = true;
                        }
                    })
                }
                Object.assign(cacheObj, this.form.person_receiverID);
                for (let key in cacheObj) {
                    list.push(key);
                }
                return list;
            },
            fileUploadSuccess(res,file) {
                console.log(res);
                let $item = $('.el-upload-list__item .el-icon-document').eq(-1);
                $item.addClass(`${FILT_ICON[this.getFileSuffix(file)] ? FILT_ICON[this.getFileSuffix(file)] : FILT_ICON.else}`);
                if (res.succeeded) {
                    this.form.fileIds.push(res.data.id)
                }
            },
            onRemove(file, fileList) {
                for (var key in this.form.fileIds) {
                    if (this.form.fileIds[key] === file.response.data.id) {
                        this.form.fileIds.splice(key, 1);
                    }
                }
            },
            getQueryString(name) {
                let result = null;
                let query = location.href.split('?')[1];
                let arr = query.split('&');
                arr.forEach((item, index) => {
                    if (item.split('=')[0] === name) {
                        result = item.split('=')[1];
                    }
                })
                return result;
            },
            jobToHtml() {
                let head = `<tr>
				<td style="border: 1px solid #ddd; font-size: 14px; padding: 10px;">职位名称</td>`
                if(MapTitle.description.show){
                    head = head + `<td style="border: 1px solid #ddd; font-size: 14px; padding: 10px;">职位描述</td>`
                }
                if(MapTitle.salary.show){
                    head = head + `<td style="border: 1px solid #ddd; font-size: 14px; padding: 10px;">月薪</td>`
                }
                if(MapTitle.analysis.show){
                    head = head + `<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">职位分析</td>`
                }
                if(MapTitle.targetCompany.show){
                    head = head + `<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">所属公司</td>`
                }
                if(MapTitle.locations.show){
                    head = head + `<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">工作地区</td>`
                }
                if(MapTitle.recruitingCount.show){
                    head = head + `<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">招聘人数</td>`
                }
                if(MapTitle.minYearOfExperience.show){
                    head = head + `<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">工作年限</td>`
                }
                if(MapTitle.minDegree.show){
                    head = head + `<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">最低学历</td>`
                }
                if(MapTitle.emergencyDegree.show){
                    head = head + `<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">紧急程度</td>`
                }
                if(MapTitle.difficultDegree.show){
                    head = head + `<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">难易程度</td>`
                }

                head = head + `<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">职位链接</td>
				</tr>`;

                let tr = '';
                this.jobData.forEach((item, index) => {
                    let td = '';
                    for(let subItem in MapTitle){
                        if (subItem !== 'analysis' && subItem !== 'jobId' && MapTitle[subItem].show) {
                            td += '<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">' + ((item[subItem] === null || item[subItem] === undefined) ? '' : item[subItem]) + '</td>';
                        } else if (subItem === 'analysis' && MapTitle[subItem].show) {
                            td += '<td style="border: 1px solid #ddd; font-size: 14px;padding: 10px;">' + ((item.expectedRank === null || item.expectedRank === undefined) ? '' : item.expectedRank) + ((item.targetCompany === null || item.targetCompany === undefined) ? '' : item.targetCompany) + ((item.analysis === null || item.analysis === undefined) ? '' : item.analysis) + '</td>';
                        } else if (subItem === 'jobId' && MapTitle[subItem].show) {
                            // let link = '<a style="cursor: pointer;" target="_blank" href="' + location.origin + '/Headhunting/MyCompany.html#/Job/' + item.jobId + '">' + '点击查看详情' + '</a>';
                            // td += '<td style="border: 1px solid #ddd; font-size: 14px; cursor: pointer;padding: 10px;">' + link + '</td>';
                            let link = '<a style="cursor: pointer;" target="_blank" href="' + location.origin + '/Headhunting/MyCompany.html#/Job/' + item.jobId + '">' + '点击查看详情' + '</a>';
                            td += '<td style="border: 1px solid #ddd; font-size: 14px; cursor: pointer;padding: 10px;">' + link + '</td>';
                        }
                    }
                    tr += '<tr>' + td + '</tr>';
                });

                let logo = '<p><img src="https://hstatic.hirede.com/lbd/images/mail-header.png" width="100%"/></p>';
                let html = '<p></p><p></p>' + logo + '<table style="border-collapse: collapse;" width="100%"><tbody>' + head + tr + '</tbody></table>';
                this.editor.setContent(html);
            },
            handleSender(e) {
                if (!e.target.value) {
                    this.addresserAdress = '';
                    shortTips('发件人邮件地址不能为空');
                    return;
                }
                if (email(e.target.value)) {
                    this.addresserAdress = e.target.value;
                } else {
                    shortTips('发件人邮件地址不合法');
                    setTimeout(() => {
                        e.target.value = '';
                    }, 24)
                }
            },
            handleAddFile() {
                document.getElementById('attachFile').click();
            },
            handleFileChange(e) {
                let files = e.target.files[0];
                if (this.fileList.length < 5 && files && files.size < MB * 2) {
                    if (this.handleValidFileType(files)) {
                        this.handleAddFileRemote(files);
                        return true;
                    }
                    shortTips('只支持 Word、Excel、压缩包 格式的文件');
                    setTimeout(() => {
                        e.target.files = null;
                    })
                    return false;
                } else if (this.fileList.length >= 5) {
                    shortTips('最多添加5个附件');
                    return false;
                } else if (files.size >= MB * 2) {
                    shortTips('单个文件最大2MB');
                    return false;
                }
            },
            handleValidFileType(file) {
                let suffix = this.getFileSuffix(file);
                if (FILT_SUFFIX[suffix]) {
                    file.iconTip = FILT_ICON[suffix];
                } else {
                    file.iconTip = 'icon-type-default';
                }
                return true;
            },
            handleReceiveInput(e, enter) {
                let value = e.target.value;
                if (!value) {
                    return false;
                }
                if (~value.indexOf(';') || enter) {
                    value = enter ? value : value.slice(0, value.length - 1);
                    if (email(value)) {
                        this.receiverList.push(value);
                    } else {
                        shortTips('邮箱地址不合法');
                    }
                    setTimeout(() => {
                        e.target.value = '';
                    })
                }
            },
            handleBlur(e) {
                this.handleReceiveInput(e, true)
            },
            handleDeleteAddress(index) {
                if (this.receiverList[index] === '公司全部成员') {
                    this.containFirm = false;
                }
                this.receiverList.splice(index, 1);
            },
            handleKeyUp(e) {
                this.handleReceiveInput(e, true);
            },
            handleDeleteFile(index) {
                this.fileList.splice(index, 1);
            },
            handleValide() {
                let result = true;
                if (this.receiverList.length == 0 && !this.containFirm) {
                    shortTips('收件人邮箱地址不能为空');
                    result = false;
                }
                return result;
            },
            handleAddAll() {
                this.containFirm = true;
                this.receiverList.unshift('公司全部成员');
            },
            handleSubmit() {
                let _self = this;
                if (this.handleValide()) {
                    this.showPageLoading();
                    let param = {
                        destinations: this.containFirm ? this.receiverList.slice(1) : this.receiverList,
                        content: this.editor.getContent() && JSON.stringify(encodeURIComponent(this.editor.getContent())),
                        subject: this.subject,
                        containFirm: this.containFirm,
                    }
                    let formData = new FormData();
                    Object.keys(param).forEach(key => {
                        formData.append(key, param[key]);
                    });

                    for (let i = 0; i < this.fileList.length; i++) {
                        formData.append('files', this.fileList[i]);
                    }
                }
            },
            upload(url, formData, successFn, errorFn, callback) {
                let _self = this;
                let xhr = new XMLHttpRequest();
                xhr.onload = function (evt) {
                    let response = JSON.parse(xhr.response);
                    if (response.succeeded) {
                        successFn(response);
                    } else {
                        errorFn(response);
                    }
                    callback && callback();
                    _self.hidePageLoading();
                };
                xhr.open('POST', url, true);
                xhr.setRequestHeader("UPASession", JSON.stringify({
                    "PageViewId": $('[name=pageViewId]').val() || ""
                }));
                xhr.send(formData);
                _self.showPageLoading();
            },
            handleAddFileRemote(file) {
                let _self = this;
                this.showPageLoading();
                let url = location.origin + '/Data/UploadFileToOSS';
                let formData = new FormData();
                formData.append('file', file);
                formData.append('purpose', 'AtAttachment');
                formData.append('__RequestVerificationToken', $('[name=__RequestVerificationToken]').val() || "");
                this.upload(url, formData, function (res) {
                    _self.hidePageLoading();
                    if (res.succeeded) {
                        res.data.iconTip = file.iconTip;
                        res.data.name = file.name;
                        _self.fileList.push(res.data);
                    } else {
                        shortTips(res.message);
                        document.getElementById('attachFile').value = '';
                    }
                }, function (err) {
                    _self.hidePageLoading();
                    shortTips(err && err.message);
                    document.getElementById('attachFile').value = '';
                }, function (r) {
                })
            },
            getFileSuffix(file) {
                if (!file) {
                    return false;
                }
                let index = file.name.lastIndexOf('.');
                return file.name.slice(index + 1);
            },
            showPageLoading() {

            },
            hidePageLoading() {

            }
            // ...mapActions(["showPageLoading", "hidePageLoading"])
        }
    }
</script>

<style lang="scss">
    .send-mail {
        // margin-top: 20px;
        padding: 20px;
        height: 100%;
        overflow-y: auto;
        &-container {
            background-color: #fff;
            border-radius: 8px;
        }

        &-header {
            padding: 0 25px;
            height: 60px;
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
                margin: 0;
                font-size: 18px;
                color: #666666;
            }
        }

        &-content {
            padding: 40px 25px 65px;
        }

        input[type="file"] {
            display: none;
        }

        .upload {
            margin-bottom: 15px;

            &:hover {
                padding: 2px;
            }
        }

        .no-border input,.el-button.no-border {
            border: none;
        }

        .el-select--max {
            width: 100%;

            .el-input input,.el-select__tags input{
                padding-right: 110px;
            }

            .el-select__tags{
                font-size: 16px;

                .el-tag{
                    margin: 0;
                    padding: 0 4px;
                    color: #444;
                    font-size: 16px;
                    background-color: transparent;
                    text-decoration: underline;
                    cursor: pointer;

                    &:hover{
                        background-color: rgba(#38bc9d , .1);

                        .el-icon-close{
                            display: inline-block !important;
                        }
                    }

                    .el-select__tags-text:after{
                        content: ';'
                    }

                    .el-tag__close.el-icon-close{
                        display: none;
                        right: 0;
                        font-weight: bold;
                        font-size: 16px;
                        color: #38bc9d;
                        background-color: transparent;
                    }

                    &.active{
                        background-color: #38bc9d;
                    }
                }

                &>span:after{
                    content: '+ 添加候选人';
                    color: #38bc9d;
                    text-decoration: none;
                    text-decoration-color: #38bc9d;
                    cursor: pointer;
                    line-height: 24px;
                }
            }
        }

        .el-input input.el-input__inner{
            border-bottom: 1px solid #ececec;
            border-radius: 0;
        }

        #container {
            iframe {
                display: block;
            }
            #edui1 {
                width: 100% !important;
            }
            #edui1_iframeholder {
                width: 88% !important;
            }
        }

        .el-input__suffix{
            cursor: pointer;
            z-index: 200;
        }
    }

    .el-scrollbar {
        .icon-member {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #FFC582;
            line-height: 27px;
            text-align: center;
            font-size: 14px;

            &:before {
                content: "\E67C";
                display: block;
                color: #fff;
            }
        }
    }

    .el-select-dropdown__item {

        &:hover{
            background-color: rgba(#38BC9C, .12);
        }

        &.selected {
            font-weight: normal;

            &:hover{
                background-color: rgba(#38BC9C, .12) !important;
            }
        }

        img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
        }
    }


    .receiver-selector{
        min-width: 418px !important;
    }

    .el-upload-list__item {
        padding: 10px;
        padding-right: 20px;
        margin-right: 40px;
        margin-top: 10px;
        border: 1px solid rgb(238, 238, 238);
        border-radius: 4px;
        width: auto;
        display: inline-block;
        background-color: rgb(248, 248, 248);
        line-height: 1;

        &:hover {
            color: rgb(82, 192, 163);
        }

        &-name {
            display: inline-block;
            margin-right: 20px;
            max-width: 202px;
        }

        &-status-label {
            display: inline-block;
            top: 10px;
            margin-bottom: 0;
        }

        .el-icon-close {
            width: 14px;
            height: 14px;
            top: 10px;
            color: rgb(82, 192, 163)
        }

        .el-icon-document{
            width: 20px;

            &::before{
                content: ' ';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 10px;
                margin: auto;
                width: 20px;
                height: 20px;
                background-size: 100%;
                background-position: center;
            }
        }

    }



    .icon-type-doc {
        &::before {
            background: url('~@src/assets/images/sendMail/icon-doc-active-small.png') no-repeat !important;
        }
    }

    .icon-type-compress {
        &::before {
            background: url('~@src/assets/images/sendMail/icon-compress-active-small.png') no-repeat !important;
        }
    }

    .icon-type-xls {
        &::before {
            background: url('~@src/assets/images/sendMail/icon-excel-active-small.png') no-repeat !important;
        }
    }

    .send-mail .el-icon-document,.icon-type-default {
        &::before {
            background: url('~@src/assets/images/sendMail/icon-default-active-small.png') no-repeat;
        }
    }
</style>
