const URL = {
    getAllEmailAddress: '/MyFirm/TeamAndMembers/List',
    sendEmail: '/Headhunting/JobsBatchImport/Notification'
};

export default {
    /**
     * 获取所有邮件联系人
     * @param {boolean} hasAvatar 是否需要头像
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    getAllEmailAddress (hasAvatar){
        return _request({
            method: 'POST',
            url: URL.getAllEmailAddress,
            data: {
                hasAvatar: hasAvatar ? hasAvatar : undefined,
            }
        })
    },
    sendEmail(batchNo,data) {
        return _request({
            method: 'POST',
            url: `${URL.sendEmail}?batchNo=${batchNo}`,
            data: data
        })
    }
}
